<template>
  <div class="menu-social-jet">
    <MenuSeparator>
      Рекламные кампании
    </MenuSeparator>
    <NuxtLink to="/order/1">
      <MenuItem :class="{ active: route.name.startsWith('order')}" >
        <template #icon>
          <i class="fi fi-sr-document-signed text-xl"></i>
        </template>
        <template #text>Заказы</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/creatives">
      <MenuItem :class="{ active: route.name === 'creatives'}">
        <template #icon>
          <i class="fi fi-sr-copy-alt text-xl"></i>
        </template>
        <template #text>Креативы</template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Настройки
    </MenuSeparator>
    <NuxtLink to="/balance">
      <MenuItem :class="{ active: route.name === 'balance'}">
        <template #icon>
          <i class="fi fi-sr-credit-card text-xl"></i>
        </template>
        <template #text>Баланс</template>
        <!-- TODO поставить реальный бланс -->
        <template #info>1 252₽</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/settings">
      <MenuItem :class="{ active: route.name === 'settings'}">
        <template #icon>
          <i class="fi fi-sr-settings text-xl"></i>
        </template>
        <template #text>
          Настройки
        </template>
      </MenuItem>
    </NuxtLink>

    <MenuSeparator>
      Сервис</MenuSeparator>
    <NuxtLink to="/news">
      <MenuItem :class="{ active: route.name === 'news'}">
        <template #icon>
          <i class="fi fi-sr-id-badge text-xl"></i>
        </template>
        <template #text>Новости</template>
      </MenuItem>
    </NuxtLink>
    <NuxtLink to="/support">
      <MenuItem :class="{ active: route.name === 'support'}">
        <template #icon>
          <i class="fi fi-sr-time-twenty-four text-xl"></i>
        </template>
        <template #text>Поддержка</template>
      </MenuItem>
    </NuxtLink>
  </div>
</template>

<script setup>
const route = useRoute();

</script>

<style>
a {
  width: 100%;
}

.menu-social-jet {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
</style>
